:root {
  --border-radius-lg: 12px;
  --border-radius-sm: 6px;
  --spacing-space-md: 12px;
  --spacing-space-base: 16px;
  --primary-btn-border-color: #149ded;
  --secondary-color: #149ded;
  --primary-border: solid 1px var(--primary-border-color);
  --secondary-border: solid 1px var(--secondary-border-color);
  --box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;
  --tooltip-bg: rgba(51, 65, 85, 0.92);
  --slate-600: #475569;
  --negative-background: #e5431f;
  --white: #fff;
  --camino-slate-slate-300: #cbd4e2;
  --camino-slate-slate-600: #475569;
  --camino-slate-slate-700: #334155;
  --camino-slate-slate-800: #1e293b;
  --camino-slate-slate-950: #020617;
  --camino-brand-too-blue-to-be-true: #0085ff;
  --camino-brand-too-blue-to-be-true-100: rgba(0, 133, 255, 0.25);
  --camino-brand-error: #e5431f;
  --camino-brand-error-100: rgba(229, 67, 31, 0.25);
  --primary-font: 'Inter', sans-serif;
  --camino-brand-primary: linear-gradient(90deg, #0085ff 0%, #b440fc 100%);
  --spacing-space-sm: 8px;
  --spacing-space-md: 12px;
  --spacing-space-base: 16px;
  --spacing-space-lg: 24px;

  --tailwind-slate-white: #ffffff;
  --tailwind-slate-slate-50: #f8fafc;
  --tailwind-slate-slate-100: #f1f5f9;
  --tailwind-slate-slate-200: #e2e7f0;
  --tailwind-slate-slate-300: #cbd4e2;
  --tailwind-slate-slate-400: #94a2b8;
  --tailwind-slate-slate-500: #64748b;
  --tailwind-slate-slate-600: #475569;
  --tailwind-slate-slate-700: #334155;
  --tailwind-slate-slate-800: #1e293b;
  --tailwind-slate-slate-900: #0f182a;
  --tailwind-slate-slate-950: #020617;

  --camino-too-blue-to-be-true: #0085ff;
  --camino-magento: #b440fc;
  --camino-aphrodite-teal: #35e9ad;
  --camino-aphrodite-aqua: #b5e3fd;
  --camino-muted-blue: #317ca5;

  --camino-gradient-1: linear-gradient(90deg, #0085ff 0%, #b440fc 100%);
  --camino-gradient-2: linear-gradient(90deg, #b440fc 0%, #35e9ad 100%);
  --camino-gradient-3: linear-gradient(90deg, #35e9ad 0%, #0085ff 100%);
  --camino-gradient-4: linear-gradient(90deg, #b440fc 0%, #0f182a 100%);

  --camino-primary-light: #b3daff;
  --camino-primary-dark: #012c5d;
  --camino-secondary-light: #e9c6fe;
  --camino-secondary-dark: #37175c;
  --camino-success-light: #18b728;
  --camino-success-dark: #09de6b;
  --camino-warning-light: #e5a21f;
  --camino-warning-dark: #e5a21f;
  --camino-error-light: #e5431f;
  --camino-error-dark: #e5431f;
}

html[data-theme='light'] {
  --bg: #f1f5f9;
  --bg-1: 255, 255, 255;
  --bg-light: #e2e8f0;
  --bg-light-1: 245, 246, 250;
  --bg-light-2: #e2e8f0;
  --primary-color: #0f172a;
  --primary-color-light: #757575;
  --selection-color: #6a91d8;
  --link-secondary: #6e7479;
  --error: #c23f38;
  --success: #6bc688;
  --info: #2c7490;
  --info-1: 44, 116, 144;
  --bg-wallet: #f5f6fa;
  --warning: #e5a21f;
  --bg-wallet-light: #ffffff;
  --primary-border-color: rgba(203, 213, 225, 0.12);
  --secondary-border-color: #e2e8f0;
  --disabled-color: #e2e8f0;
  --primary-contrast-text: #0f172a;
  --secondary-contrast-text: #ffffff;
  --sidebar-links: #64748b;
  --sidebar-background: #fff;
  --icon-color: #0f172a;
  --icon-color-light: #334155;
  --camino-slate-slate-white: #ffffff;
  --camino-info-border: rgba(0, 133, 255, 0.5);
  --camino-info-background: rgba(0, 133, 255, 0.05);
  --camino-positive-border: rgba(9, 222, 107, 0.5);
  --camino-positive-background: rgba(9, 222, 107, 0.05);
  --camino-warning-border: rgba(229, 162, 31, 0.5);
  --camino-warning-background: rgba(229, 162, 31, 0.05);
  --camino-negative-border: rgba(229, 67, 31, 0.5);
  --camino-negative-background: rgba(229, 67, 31, 0.05);
  --camino-warning-color: #334155;
  --camino-alert-color: #334155;
  --camino-success-color: rgba(24, 183, 40, 1);
  --camino-error-color: rgba(229, 67, 31, 1);
}

html[data-theme='dark'] {
  --bg: #020617;
  --bg-1: 36, 39, 41;
  --bg-light: #1e293b;
  --bg-light-1: 46, 50, 52;
  --bg-light-2: #334155;
  --primary-color: #f5f5f5;
  --primary-color-light: #6e7479;
  --selection-color: #6a91d8;
  --link-secondary: #6e7479;
  --error: #c23f38;
  --success: #6bc688;
  --info: #4c8caf;
  --info-1: 76, 140, 175;
  --bg-wallet: #334155;
  --warning: #f19d38;
  --bg-wallet-light: #0f172a;
  --primary-border-color: rgba(255, 255, 255, 0.12);
  --secondary-border-color: rgba(255, 255, 255, 0.12);
  --disabled-color: rgba(255, 255, 255, 0.12);
  --primary-contrast-text: #ffffff;
  --secondary-contrast-text: #0f172a;
  --sidebar-links: #64748b;
  --sidebar-background: #1e293b;
  --icon-color: #ffffff;
  --icon-color--light: #ffffff;
  --camino-brand-too-blue-to-be-true: #0085ff;
  --camino-info-border: rgba(0, 133, 255, 0.3);
  --camino-info-background: rgba(0, 133, 255, 0.05);
  --camino-positive-border: rgba(9, 222, 107, 0.3);
  --camino-positive-background: rgba(9, 222, 107, 0.05);
  --camino-warning-border: rgba(229, 162, 31, 0.3);
  --camino-warning-background: rgba(229, 162, 31, 0.05);
  --camino-negative-border: rgba(229, 67, 31, 0.3);
  --camino-negative-background: rgba(229, 67, 31, 0.05);
  --camino-warning-color: #cbd4e2;
  --camino-alert-color: #cbd4e2;
  --camino-success-color: rgba(9, 222, 107, 1);
  --camino-error-color: rgba(229, 67, 31, 1);
  --camino-slate-slate-950: #020617;
  --camino-slate-slate-600: #475569;
}
body {
  overflow: auto;
  background-color: var(--bg) !important;
  height: 100%;
  font-size: 16px !important;
  padding-right: 0 !important;
}

#app {
  display: flex;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: var(--primary-color);
  font-family: 'Rubik', sans-serif;
  transition-duration: 0.2s;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  padding: 20px;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .container {
    padding: 10px;
    height: auto;
  }
}
